<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            @click="getData()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Badan</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Data Badan </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-row>
            <v-col cols="12" md="6">
              <!-- Golongan Darah -->
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Golongan Darah</span>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="golonganDarah"
                      :items="refGoldarah"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Tinggi Badan -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Tinggi Badan</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="tinggiBadan"
                      dense
                      outlined
                      suffix="Cm"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Berat Badan -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Berat Badan</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="beratBadan"
                      dense
                      outlined
                      suffix="Kg"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Rambut -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Rambut</span>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="janisRambut"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>

            <v-col cols="12" md="6">
              <!-- Bentuk Muka -->
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Bentuk Muka</span>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="bentukWajah"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Warna Kulit -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Warna Kulit</span>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="warnaKulit"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Ciri Khas -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Ciri Khas</span>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="ciriBadan"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Cacat Tubuh -->
              <v-col cols="12" class="mt-n5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-3">
                    <span>Cacat Tubuh</span>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="cacatTubuh"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/pns/databadan/viewDatabadan";

export default {
  name: "rwpenghargaanModalEdit",

  data: () => ({
    token: Cookie.get("token"),
    ModalEdit: false,
    btnLoading: true,

    refGoldarah: ["A", "B", "O", "AB"],

    golonganDarah: "",
    tinggiBadan: "",
    beratBadan: "",
    janisRambut: "",
    bentukWajah: "",
    warnaKulit: "",
    ciriBadan: "",
    cacatTubuh: "",
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async getData() {
      this.get_nip = this.$route.params.id;

      const url = process.env.VUE_APP_ASN + "fisik/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.golonganDarah = response.data.mapData.DataFisik.golonganDarah;
          this.tinggiBadan = response.data.mapData.DataFisik.tinggiBadan;
          this.beratBadan = response.data.mapData.DataFisik.beratBadan;
          this.janisRambut = response.data.mapData.DataFisik.janisRambut;
          this.bentukWajah = response.data.mapData.DataFisik.bentukWajah;
          this.warnaKulit = response.data.mapData.DataFisik.warnaKulit;
          this.ciriBadan = response.data.mapData.DataFisik.ciriBadan;
          this.cacatTubuh = response.data.mapData.DataFisik.cacatTubuh;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        golonganDarah: this.golonganDarah,
        tinggiBadan: this.tinggiBadan,
        beratBadan: this.beratBadan,
        janisRambut: this.janisRambut,
        bentukWajah: this.bentukWajah,
        warnaKulit: this.warnaKulit,
        ciriBadan: this.ciriBadan,
        cacatTubuh: this.cacatTubuh,
      };

      const url = process.env.VUE_APP_ASN + "fisik/nip/" + this.get_nip;
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
        });
    },

    closeModal() {
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>
