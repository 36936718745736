<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="6">
        <h2>Riwayat Diklat</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <rwdiklatModalAdd />
      </v-col>
    </v-row>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="rwdiklats"
        :items-per-page="-1"
        :mobile-breakpoint="0"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwdiklatModalEdit />
          <rwdiklatModalHapus />
        </template>
        <template v-slot:[`item.penyelenggara`]="{ item }">
          Nomor  : {{ item.nomor }} <br />
          Tanggal : {{ item.tanggal }} <br />
          Penyelenggara : {{ item.penyelenggara }}
        </template>
        <template v-slot:[`item.tempat`]="{ item }">
          Tempat : {{ item.tempat }} <br />
          {{ item.pelaksanaan.awal }} S/d {{ item.pelaksanaan.akhir }} <br />
          Jumlah Jam : {{ item.pelaksanaan.jp }}
          <span v-if="item.pelaksanaan.jp != ''">Jp</span>
        </template>
        <template v-slot:[`item.kualifikasi`]="{ item }">
          Kualifikasi : {{ item.kualifikasi.nama }} <br />
          Nilai : {{ item.nilai }} <br />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwdiklatModalAdd from "@/components/pns/profil/rwdiklat/ModalAdd";
import rwdiklatModalEdit from "@/components/pns/profil/rwdiklat/ModalEdit";
import rwdiklatModalHapus from "@/components/pns/profil/rwdiklat/ModalHapus";

import modal_editState from "@/store/pns/rwdiklat/modal_edit";
import modal_hapusState from "@/store/pns/rwdiklat/modal_hapus";
import refreshView from "@/store/pns/rwdiklat/viewRwdiklat";

export default {
  name: "rwdiklat",

  components: {
    rwdiklatModalAdd,
    rwdiklatModalEdit,
    rwdiklatModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    get_nip: "",

    rwdiklats: [],
    rwdiklat: {},

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    headers: [
      { text: "Nama Diklat", align: "start", value: "latihanStruktural.nama" },
      {
        text: "Nomor dan Tanggal Ijazah Penyelenggara",
        value: "penyelenggara",
      },
      {
        text: "Tempat / Tanggal Pelaksanaan Jumlah Jam",
        value: "tempat",
      },
      { text: "Kualifikasi / Nilai", value: "kualifikasi" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      const url = process.env.VUE_APP_ASN + "diklat/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.rwdiklats = response.data.mapData.RwDiklat;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwdiklats.indexOf(item);
      this.rwdiklat = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwdiklats.indexOf(item);
      this.rwdiklat = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
