<template>
  <div>
    <div class="mb-5">
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>

      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <h2><b>Data Orang Tua</b></h2>
          </v-col>
          <v-col cols="6" class="text-right">
            <rworangtuaModalEdit />
          </v-col>
        </v-row>
      </v-col>
      <hr class="mb-3" />

      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nama Orang Tua</th>
                <th class="text-left">Tempat Lahir</th>
                <th class="text-left">Tanggal Lahir</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ namaIbu }}</td>
                <td>{{ tempatlahirIbu }}</td>
                <td>{{ tgllahirIbu }}</td>
              </tr>
              <tr>
                <td>{{ namaAyah }}</td>
                <td>{{ tempatlahirAyah }}</td>
                <td>{{ tgllahirAyah }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>

    <div>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <h2><b>Data Mertua</b></h2>
          </v-col>
        </v-row>
      </v-col>

      <hr class="mb-3" />

      <v-card>
        <v-data-table
          :headers="headers"
          :items="mertuas"
          :items-per-page="-1"
          :mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:top>
            <rworangtuaMertuaModalEdit />
          </template>
          <template v-slot:[`item.kolom1`]="{ item }">
            {{ item.bapak.nama }} <br />
            {{ item.ibu.nama }}
          </template>
          <template v-slot:[`item.kolom2`]="{ item }">
            {{ item.bapak.tempatLahir }} <br />
            {{ item.ibu.tempatLahir }}
          </template>
          <template v-slot:[`item.kolom3`]="{ item }">
            {{ item.bapak.tglLahir }} <br />
            {{ item.ibu.tglLahir }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import apiURL from "@/helper/getURL.js";
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rworangtuaModalEdit from "@/components/pns/profil/rworangtua/ModalEdit";
import rworangtuaMertuaModalEdit from "@/components/pns/profil/rworangtua/MertuaModalEdit";

import refreshView from "@/store/pns/rworangtua/viewRworangtua";
import modal_editState from "@/store/pns/rworangtua/modal_edit";

export default {
  name: "rworangtua",

  components: {
    rworangtuaModalEdit,
    rworangtuaMertuaModalEdit,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ortus: [],
    mertuas: [],
    mertua: {},

    namaIbu: "",
    tempatlahirIbu: "",
    tgllahirIbu: "",

    namaAyah: "",
    tempatlahirAyah: "",
    tgllahirAyah: "",

    //Kebutuhan Edit
    editedIndex: -1,

    headers: [
      { text: "Nama Pasangan", align: "start", value: "nama" },
      { text: "Nama Mertua", align: "start", value: "kolom1" },
      { text: "Tempat Lahir", value: "kolom2" },
      { text: "Tanggal Lahir", value: "kolom3" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      const token = Cookie.get("token");
      const url = apiURL.baseURL + "ortu/nip/" + this.get_nip;

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.ortus = response.data.mapData.DataKeluarga.dataOrtu;
          this.namaIbu = response.data.mapData.DataKeluarga.dataOrtu.ibu.nama;
          this.tempatlahirIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.tempatLahir;
          this.tgllahirIbu =
            response.data.mapData.DataKeluarga.dataOrtu.ibu.tglLahir;
          this.namaAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.nama;
          this.tempatlahirAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.tempatLahir;
          this.tgllahirAyah =
            response.data.mapData.DataKeluarga.dataOrtu.bapak.tglLahir;

          this.mertuas = response.data.mapData.DataKeluarga.dataMertua;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.mertuas.indexOf(item);
      this.rwluarnegeri = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },
  },
};
</script>
