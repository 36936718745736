<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Tambah Riwayat Kursus </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Kursus -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kursus</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="jenisKursus"
                  :items="refKursus"
                  item-value="id"
                  item-text="nama"
                  hint="Jenis Kursus"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="namaKursus"
                  hint="Nama Kursus"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="sertifikat"
                  :items="refSertifikat"
                  item-value="id"
                  item-text="nama"
                  hint="Sertifikat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal / Jam Pelaksanaan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal / Jam Pelaksanaan</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="tempat"
                  hint="Tempat Pelaksanaan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAwalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal Awal"
                      persistent-hint
                      v-model="tglAwalDate"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglAwalDate"
                    @input="tglAwalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAkhirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal Akhir"
                      persistent-hint
                      v-model="tglAkhirDate"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglAkhirDate"
                    @input="tglAkhirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="jamPelaksanaan"
                  hint="Jam"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Penyelenggara -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Penyelenggara</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="institusiPenyelenggara"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Sertifikat -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Sertifikat</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="noSertipikat"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglKursusPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="tanggalKursus"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tanggalKursus"
                    @input="tglKursusPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="tahunKursus"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/rwkursus/viewRwkursus";

export default {
  name: "rwkursusModalAdd",

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refKursus = await getRef.JenisKursus(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,

    // Date Picker
    tglAwalPicker: false,
    tglAkhirPicker: false,
    tglKursusPicker: false,

    // Select
    refKursus: [],
    refSertifikat: [
      {
        id: "KURSUS",
        nama: "Kursus",
      },
      {
        id: "SERTIFIKAT",
        nama: "Sertifikasi",
      },
    ],

    // variable
    jenisKursus: "",
    namaKursus: "",
    sertifikat: "",
    noSertipikat: "",
    tanggalKursus: "",
    tahunKursus: "",
    institusiPenyelenggara: "",
    tempat: "",
    tglAwalDate: "",
    tglAkhirDate: "",
    jamPelaksanaan: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.btnLoading = false;

      let data = {
        jenisKursus: {
          id: this.jenisKursus,
          sertifikat: this.sertifikat,
        },
        namaKursus: this.namaKursus,
        noSertipikat: this.noSertipikat,
        tanggalKursus: this.tanggalKursus,
        tahunKursus: this.tahunKursus,
        institusiPenyelenggara: this.institusiPenyelenggara,
        tempat: this.tempat,
        pelaksanaan: {
          awal: this.tglAwalDate,
          akhir: this.tglAkhirDate,
          jp: this.jamPelaksanaan,
        },
      };

      const url = process.env.VUE_APP_ASN + "kursus/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },
    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
