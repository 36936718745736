<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            @click="getData()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Pendukung</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Data Pendukung </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <!-- NPWP -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>NPWP</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="npwpNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="npwpDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="npwpTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="npwpTanggal"
                    @input="npwpDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="npwpStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kartu Pegawai -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kartu Pegawai</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="kpNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="kpDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="kpTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="kpTanggal"
                    @input="kpDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="kpStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kartu Istri -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kartu Istri</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="ksNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="ksDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="ksTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ksTanggal"
                    @input="ksDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="ksStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- TASPEN -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>TASPEN</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="tpNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tpDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="tpTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tpTanggal"
                    @input="tpDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="tpStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Asuransi Kesehatan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Asuransi Kesehatan</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="akNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="akDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="akTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="akTanggal"
                    @input="akDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="akStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kartu Keluarga -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kartu Keluarga</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="kkNomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="kkDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="kkTanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="kkTanggal"
                    @input="kkDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="kkStatus"
                  :items="refStatusdok"
                  item-value="id"
                  item-text="nama"
                  hint="Status"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/dokpendukung/viewDokpendukung";

export default {
  name: "rwpenghargaanModalEdit",

  data: () => ({
    token: Cookie.get("token"),
    ModalEdit: false,
    btnLoading: true,

    // Date Picker
    npwpDate: false,
    kpDate: false,
    ksDate: false,
    tpDate: false,
    akDate: false,
    kkDate: false,

    npwpNomor: "",
    npwpTanggal: "",
    npwpStatus: "",
    kpNomor: "",
    kpTanggal: "",
    kpStatus: "",
    ksNomor: "",
    ksTanggal: "",
    ksStatus: "",
    tpNomor: "",
    tpTanggal: "",
    tpStatus: "",
    akNomor: "",
    akTanggal: "",
    akStatus: "",
    kkNomor: "",
    kkTanggal: "",
    kkStatus: "",

    refStatusdok: [],
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async getData() {
      this.get_nip = this.$route.params.id;
      this.refStatusdok = await getRef.Statusdok(this.token);

      const url = process.env.VUE_APP_ASN + "pendukung/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.npwpNomor = response.data.mapData.DataPendukung.npwp.nomor;
          this.npwpTanggal = response.data.mapData.DataPendukung.npwp.tanggal;
          this.npwpStatus = response.data.mapData.DataPendukung.npwp.status.id;
          this.kpNomor =
            response.data.mapData.DataPendukung.kartuPegegawai.nomor;
          this.kpTanggal =
            response.data.mapData.DataPendukung.kartuPegegawai.tanggal;
          this.kpStatus =
            response.data.mapData.DataPendukung.kartuPegegawai.status.id;
          this.ksNomor = response.data.mapData.DataPendukung.karSuIs.nomor;
          this.ksTanggal = response.data.mapData.DataPendukung.karSuIs.tanggal;
          this.ksStatus = response.data.mapData.DataPendukung.karSuIs.status.id;
          this.tpNomor = response.data.mapData.DataPendukung.taspen.nomor;
          this.tpTanggal = response.data.mapData.DataPendukung.taspen.tanggal;
          this.tpStatus = response.data.mapData.DataPendukung.taspen.status.id;
          this.akNomor =
            response.data.mapData.DataPendukung.asuransiKesehatan.nomor;
          this.akTanggal =
            response.data.mapData.DataPendukung.asuransiKesehatan.tanggal;
          this.akStatus =
            response.data.mapData.DataPendukung.asuransiKesehatan.status.id;
          this.kkNomor = response.data.mapData.DataPendukung.kk.nomor;
          this.kkTanggal = response.data.mapData.DataPendukung.kk.tanggal;
          this.kkStatus = response.data.mapData.DataPendukung.kk.status.id;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        npwp: {
          status: {
            id: this.npwpStatus,
          },

          nomor: this.npwpNomor,
          tanggal: this.npwpTanggal,
        },
        kartuPegegawai: {
          status: {
            id: this.kpStatus,
          },
          nomor: this.kpNomor,
          tanggal: this.kpTanggal,
        },
        karSuIs: {
          status: {
            id: this.ksStatus,
          },
          nomor: this.ksNomor,
          tanggal: this.ksTanggal,
        },
        taspen: {
          status: {
            id: this.tpStatus,
          },
          nomor: this.tpNomor,
          tanggal: this.tpTanggal,
        },
        asuransiKesehatan: {
          status: {
            id: this.akStatus,
          },
          nomor: this.akNomor,
          tanggal: this.akTanggal,
        },
        kk: {
          status: {
            id: this.kkStatus,
          },
          nomor: this.kkNomor,
          tanggal: this.kkTanggal,
        },
      };

      const url = process.env.VUE_APP_ASN + "pendukung/nip/" + this.get_nip;
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          console.log(error.response);
          console.log(error);
          this.btnLoading = true;
        });
    },

    closeModal() {
      this.reset();
      this.ModalEdit = false;
    },
  },
};
</script>
