<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="6">
        <h2>Data Pasangan</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <rwpasanganModalAdd />
      </v-col>
    </v-row>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="rwpasangans"
        :mobile-breakpoint="0"
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:top>
          <rwpasanganModalEdit />
          <rwpasanganModalHapus />
        </template>
        <template v-slot:[`item.kolom1`]="{ item }">
          {{ item.kelPasangan.nama }} <br />
          {{ item.kelPasangan.tempatLahir }}, {{ item.kelPasangan.tglLahir }}
        </template>
        <template v-slot:[`item.kolom2`]="{ item }">
          {{ item.kelPasangan.dataPernikahan.aktaMenikah }} <br />
          {{ item.kelPasangan.dataPernikahan.tgglMenikah }}
        </template>
        <template v-slot:[`item.kolom3`]="{ item }">
          <div v-if="item.kelPasangan.dataPernikahan.aktaCerai">
            Cerai<br />
            {{ item.kelPasangan.dataPernikahan.aktaCerai }} <br />
            {{ item.kelPasangan.dataPernikahan.tgglCerai }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwpasanganModalAdd from "@/components/pns/profil/rwpasangan/ModalAdd";
import rwpasanganModalEdit from "@/components/pns/profil/rwpasangan/ModalEdit";
import rwpasanganModalHapus from "@/components/pns/profil/rwpasangan/ModalHapus";

import modal_editState from "@/store/pns/rwpasangan/modal_edit";
import modal_hapusState from "@/store/pns/rwpasangan/modal_hapus";
import refreshView from "@/store/pns/rwpasangan/viewRwpasangan";

export default {
  name: "rwpasangan",

  components: {
    rwpasanganModalAdd,
    rwpasanganModalEdit,
    rwpasanganModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    rwpasangans: [],
    get_nip: "",

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    headers: [
      {
        text: "Nama Istri / Tempat Tanggal lahir",
        align: "start",
        sortable: false,
        value: "kolom1",
      },
      { text: "Akta Nikah / Tanggal Nikah", value: "kolom2" },
      { text: "Keterangan", value: "kolom3" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      const url = process.env.VUE_APP_ASN + "pasangan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.rwpasangans = response.data.mapData.DataKeluarga;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwpasangans.indexOf(item);
      this.rwpasangan = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwpasangans.indexOf(item);
      this.rwpasangan = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
