<template>
  <div>
    <v-banner>
      DATA PNS - <b>{{ $route.params.id }}</b>
    </v-banner>

    <div class="mt-5">
      <v-tabs v-model="tab">
        <v-tab>Data Utama</v-tab>
        <v-tab>Data Riwayat</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- TAB DATA UTAMA -->
        <v-tab-item>
          <v-slide-group class="pa-4" show-arrows>
            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="datapribadi()"
                width="150"
              >
                <v-icon x-large color="primary">mdi-database-lock</v-icon>
                <h4 class="mt-1">Data Pribadi</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="pasangan()"
                width="150"
              >
                <v-icon x-large color="primary"
                  >mdi-human-male-female-child</v-icon
                >
                <h4 class="mt-1">Data Pasangan</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="anak()"
                width="150"
              >
                <v-icon x-large color="primary"
                  >mdi-account-child-circle</v-icon
                >
                <h4 class="mt-1">Data Anak</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="orangtua()"
                width="150"
              >
                <v-icon x-large color="primary">mdi-human-male-female</v-icon>
                <h4 class="mt-1">Data Orangtua & Mertua</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="saudara()"
                width="150"
              >
                <v-icon x-large color="primary"
                  >mdi-human-capacity-decrease</v-icon
                >
                <h4 class="mt-1">Data Saudara</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="badan()"
                width="150"
              >
                <v-icon x-large color="primary">mdi-human-male-height</v-icon>
                <h4 class="mt-1">Keterangan Badan & Tubuh</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="pendukung()"
                width="150"
              >
                <v-icon x-large color="primary">mdi-database-plus</v-icon>
                <h4 class="mt-1">Data Pendukung</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                class="text-center primary--text pa-2 mx-2"
                @click="lainnya()"
                width="150"
              >
                <v-icon x-large color="primary"
                  >mdi-database-refresh-outline</v-icon
                >
                <h4 class="mt-1">Data Lainnya</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-tab-item>

        <!-- TAB DATA RIWAYAT -->
        <v-tab-item>
          <v-slide-group class="pa-4" show-arrows>
            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwjabatan()"
              >
                <h4>Riwayat Jabatan / Pekerjaan</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwpangkat()"
              >
                <h4>Riwayat Pangkat / Golongan Ruang</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwdiklat()"
              >
                <h4>Riwayat Diklat</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwkursus()"
              >
                <h4>Riwayat Kursus</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwpendidikan()"
              >
                <h4>Riwayat Pendidikan / Sekolah</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwpenghargaan()"
              >
                <h4>Riwayat Penghargaan</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwkgb()"
              >
                <h4>Riwayat Kenaikan Gaji Berkala</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwskp()"
              >
                <h4>Riwayat Sasaran Kinerja Pegawai</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rworganisasi()"
              >
                <h4>Riwayat Organisasi</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwluarnegeri()"
              >
                <h4>Pengalaman Luar Negeri</h4>
              </v-card>
            </v-slide-item>

            <v-slide-item>
              <v-card
                flat
                color="primary"
                class="text-center white--text pa-2 mx-2"
                @click="rwbahasa()"
              >
                <h4>Bahasa yang dikuasai</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-tab-item>
      </v-tabs-items>

      <v-divider class="my-7"></v-divider>

      <!-- TAB DATA UTAMA -->
      <div>
        <div v-if="Vdatapribadi">
          <!-- Data Pribadi -->
          <CDatapribadi />
        </div>

        <div v-if="Vrwpasangan">
          <!-- Data Pasangan -->
          <Crwpasangan />
        </div>

        <div v-if="Vrwanak">
          <!-- Data Anak -->
          <Crwanak />
        </div>

        <div v-if="Vrworangtua">
          <!-- Data Orangtua & Mertua -->
          <Crworangtua />
        </div>

        <div v-if="Vrwsaudara">
          <!-- Data Saudara -->
          <Crwsaudara />
        </div>

        <div v-if="Vdatabadan">
          <!-- Data Badan -->
          <Cdatabadan />
        </div>

        <div v-if="Vdatapendukung">
          <!-- Data Pendukung -->
          <Cdatapendukung />
        </div>

        <div v-if="Vdatalainnya">
          <!-- Data Pendukung -->
          <Cdatalainnya />
        </div>
      </div>

      <!-- TAB DATA RIWAYAT -->
      <div>
        <div v-if="Vrwjabatan">
          <!-- rwjabatan -->
          <Crwjabatan />
        </div>
      </div>

      <div>
        <div v-if="Vrwpangkat">
          <!-- rwpangkat -->
          <Crwpangkat />
        </div>
      </div>

      <div>
        <div v-if="Vrwdiklat">
          <!-- rwdiklat -->
          <Crwdiklat />
        </div>
      </div>

      <div>
        <div v-if="Vrwkursus">
          <!-- rwkursus -->
          <Crwkursus />
        </div>
      </div>

      <div>
        <div v-if="Vrwpendidikan">
          <!-- rwpendidikan -->
          <Crwpendidikan />
        </div>
      </div>

      <div>
        <div v-if="Vrwpenghargaan">
          <!-- rwpenghargaan -->
          <Crwpenghargaan />
        </div>
      </div>

      <div>
        <div v-if="Vrwkgb">
          <!-- rwkgb -->
          <Crwkgb />
        </div>
      </div>

      <div>
        <div v-if="Vrwskp">
          <!-- rwskp -->
          <Crwskp />
        </div>
      </div>

      <div>
        <div v-if="Vrworganisasi">
          <!-- rworganisasi -->
          <Crworganisasi />
        </div>
      </div>

      <div>
        <div v-if="Vrwluarnegeri">
          <!-- rwluarnegeri -->
          <Crwluarnegeri />
        </div>
      </div>

      <div>
        <div v-if="Vrwbahasa">
          <!-- rwbahasa -->
          <Crwbahasa />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TAB DATA UTAMA
import CDatapribadi from "@/components/pns/profil/datautama/datautama-v2";
import Crwpasangan from "@/components/pns/profil/rwpasangan/rwpasangan";
import Crwanak from "@/components/pns/profil/rwanak/rwanak";
import Crworangtua from "@/components/pns/profil/rworangtua/rworangtua";
import Crwsaudara from "@/components/pns/profil/rwsaudara/rwsaudara";
import Cdatabadan from "@/components/pns/profil/databadan/databadan";
import Cdatapendukung from "@/components/pns/profil/dokpendukung/dokpendukung";
import Cdatalainnya from "@/components/pns/profil/doktambahan/doktambahan";

// TAB DATA RIWAYAT
import Crwjabatan from "@/components/pns/profil/rwjabatan/rwjabatan";
import Crwpangkat from "@/components/pns/profil/rwpangkat/rwpangkat";
import Crwdiklat from "@/components/pns/profil/rwdiklat/rwdiklat";
import Crwkursus from "@/components/pns/profil/rwkursus/rwkursus";
import Crwpendidikan from "@/components/pns/profil/rwpendidikan/rwpendidikan";
import Crwpenghargaan from "@/components/pns/profil/rwpenghargaan/rwpenghargaan";
import Crwkgb from "@/components/pns/profil/rwgaji/rwgaji";
import Crwskp from "@/components/pns/profil/rwskp/rwskp";
import Crworganisasi from "@/components/pns/profil/rworganisasi/rworganisasi";
import Crwluarnegeri from "@/components/pns/profil/rwluarnegeri/rwluarnegeri";
import Crwbahasa from "@/components/pns/profil/rwbahasa/rwbahasa";

export default {
  components: {
    // TAB DATA UTAMA
    CDatapribadi,
    Crwpasangan,
    Crwanak,
    Crworangtua,
    Crwsaudara,
    Cdatabadan,
    Cdatapendukung,
    Cdatalainnya,

    // TAB DATA RIWAYAT
    Crwjabatan,
    Crwpangkat,
    Crwdiklat,
    Crwkursus,
    Crwpendidikan,
    Crwpenghargaan,
    Crwkgb,
    Crwskp,
    Crworganisasi,
    Crwluarnegeri,
    Crwbahasa,
  },

  data: () => ({
    tab: null,

    // TAB DATA UTAMA
    Vdatapribadi: false,
    Vrwpasangan: false,
    Vrwanak: false,
    Vrworangtua: false,
    Vrwsaudara: false,
    Vdatabadan: false,
    Vdatapendukung: false,
    Vdatalainnya: false,

    // TAB DATA RIWAYAT
    Vrwjabatan: false,
    Vrwpangkat: false,
    Vrwdiklat: false,
    Vrwkursus: false,
    Vrwpendidikan: false,
    Vrwpenghargaan: false,
    Vrwkgb: false,
    Vrwskp: false,
    Vrworganisasi: false,
    Vrwluarnegeri: false,
    Vrwbahasa: false,
  }),

  methods: {
    // TAB DATA UTAMA
    datapribadi() {
      this.Vdatapribadi = true;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    pasangan() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = true;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    anak() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = true;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    orangtua() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = true;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    saudara() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = true;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    badan() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = true;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    pendukung() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = true;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    lainnya() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = true;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    // TAB DATA RIWAYAT
    rwjabatan() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = true;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwpangkat() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = true;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwdiklat() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = true;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwkursus() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = true;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwpendidikan() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = true;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwpenghargaan() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = true;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwkgb() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = true;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwskp() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = true;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rworganisasi() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = true;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = false;
    },

    rwluarnegeri() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = true;
      this.Vrwbahasa = false;
    },

    rwbahasa() {
      this.Vdatapribadi = false;
      this.Vrwpasangan = false;
      this.Vrwanak = false;
      this.Vrworangtua = false;
      this.Vrwsaudara = false;
      this.Vdatabadan = false;
      this.Vdatapendukung = false;
      this.Vdatalainnya = false;

      this.Vrwjabatan = false;
      this.Vrwpangkat = false;
      this.Vrwdiklat = false;
      this.Vrwkursus = false;
      this.Vrwpendidikan = false;
      this.Vrwpenghargaan = false;
      this.Vrwkgb = false;
      this.Vrwskp = false;
      this.Vrworganisasi = false;
      this.Vrwluarnegeri = false;
      this.Vrwbahasa = true;
    },
  },
};
</script>