<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>
          Tambah Riwayat Pangkat / Golongan Ruang
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-8">
          <v-row>
            <!-- PANGKAT / TMT -->
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Pangkat / TMT</span>
                </v-col>
                <v-col cols="12" md="7">
                  <v-autocomplete
                    v-model="golongan"
                    :items="refGolongan"
                    item-value="id"
                    outlined
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.pangkat }} - {{ data.item.nama }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.pangkat }} - {{ data.item.nama }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="tmtPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tmtDate"
                        hint="TMT"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tmtDate"
                      @input="tmtPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- SURAT KEPUTUSAN (SK) -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Surat Keputusan (SK)</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="pejabat"
                    hint="Pejabat"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="nomor"
                    hint="Nomor"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu
                    v-model="skPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="skDate"
                        hint="Tanggal"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="skDate"
                      @input="skPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- PERSETUJUAN BKN -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Persetujuan BKN</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="nomorBKN"
                    hint="Nomor"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu
                    v-model="persetujuanPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persetujuanDate"
                        hint="Tanggal"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="persetujuanDate"
                      @input="persetujuanPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- GAJI POKOK / MASA KERJA -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Gaji Pokok / Masa kerja</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="gajiPokok"
                    hint="Gaji Pokok"
                    placeholder="Rp."
                    type="number"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="tahun"
                    hint="Masa Kerja Tahun"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="bulan"
                    hint="Masa Kerja Bulan"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- JENIS KENPA -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Jenis Kenpa</span>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-model="jenisKP"
                    :items="refjenisKP"
                    item-text="nama"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/pns/rwpangkat/viewRwpangkat";

export default {
  name: "rwpangkatModalAdd",

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refGolongan = await getRef.Golongan(this.token);
        this.refjenisKP = await getRef.jenisKP(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    ModalAdd: false,

    // Date Picker
    tmtPicker: false,
    skPicker: false,
    persetujuanPicker: false,

    // Referensi
    refGolongan: [],
    refjenisKP: [],

    // Variable
    golongan: "",
    tmtDate: "",
    nomorBKN: "",
    persetujuanDate: "",
    pejabat: "",
    nomor: "",
    skDate: "",
    tahun: "",
    bulan: "",
    jenisKP: "",
    gajiPokok: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.btnLoading = false;

      let data = {
        golongan: {
          id: this.golongan,
          tmt: this.tmtDate,
        },
        pertekBkn: {
          nomor: this.nomorBKN,
          tanggal: this.persetujuanDate,
        },
        suratKeputusan: {
          pejabat: this.pejabat,
          nomor: this.nomor,
          tanggal: this.skDate,
        },
        jumlahKredit: {
          utama: null,
          tambahan: null,
        },
        masaKerjaGolongan: {
          tahun: this.tahun,
          bulan: this.bulan,
        },
        jenisKP: {
          id: this.jenisKP,
        },
        gajiPokok: this.gajiPokok,
      };

      const url = process.env.VUE_APP_ASN + "golongan/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.reset();
          this.ModalAdd = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
            this.reset();
            this.ModalAdd = false;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
            this.reset();
            this.ModalAdd = false;
          }
        });
    },
  },
};
</script>
