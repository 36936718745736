<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>
          Edit Riwayat Pangkat / Golongan Ruang
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-8">
          <v-row>
            <!-- PANGKAT / TMT -->
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Pangkat / TMT</span>
                </v-col>
                <v-col cols="12" md="7">
                  <v-autocomplete
                    v-model="editedItem.golongan.id"
                    :items="refGolongan"
                    item-value="id"
                    outlined
                    dense
                  >
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.pangkat }} - {{ data.item.nama }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.pangkat }} - {{ data.item.nama }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-menu
                    v-model="tmtPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.golongan.tmt"
                        hint="TMT"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.golongan.tmt"
                      @input="tmtPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- SURAT KEPUTUSAN (SK) -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Surat Keputusan (SK)</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.suratKeputusan.pejabat"
                    hint="Pejabat"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.suratKeputusan.nomor"
                    hint="Nomor"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu
                    v-model="skPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.suratKeputusan.tanggal"
                        hint="Tanggal"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.suratKeputusan.tanggal"
                      @input="skPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- PERSETUJUAN BKN -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Persetujuan BKN</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.pertekBkn.nomor"
                    hint="Nomor"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu
                    v-model="persetujuanPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.pertekBkn.tanggal"
                        hint="Tanggal"
                        persistent-hint
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.pertekBkn.tanggal"
                      @input="persetujuanPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <!-- GAJI POKOK / MASA KERJA -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Gaji Pokok / Masa kerja</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.gajiPokok"
                    hint="Gaji Pokok"
                    placeholder="Rp."
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="editedItem.masaKerjaGolongan.tahun"
                    hint="Masa Kerja Tahun"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="editedItem.masaKerjaGolongan.bulan"
                    hint="Masa Kerja Bulan"
                    persistent-hint
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- JENIS KENPA -->
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="12" md="2" class="mt-3">
                  <span>Jenis Kenpa</span>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-model="editedItem.jenisKP.id"
                    :items="refjenisKP"
                    item-text="nama"
                    item-value="id"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import modal_editState from "@/store/pns/rwpangkat/modal_edit";
import refreshView from "@/store/pns/rwpangkat/viewRwpangkat";

export default {
  name: "rwpangkatModalEdit",

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refGolongan = await getRef.Golongan(this.token);
        this.refjenisKP = await getRef.jenisKP(this.token);
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwpangkat;
      },
    },

    editedDatautama: {
      get() {
        return modal_editState.state.dataUtama;
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    rwpangkat: {},

    // Date Picker
    tmtPicker: false,
    skPicker: false,
    persetujuanPicker: false,

    // Referensi
    refGolongan: [],
    refjenisKP: [],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.rwpangkat = {
        id: "",
        idASN: "",
        sapk: {
          id: "",
          idASN: "",
        },
        golongan: {
          id: "",
          tmt: "",
        },
        pertekBkn: {
          nomor: "",
          tanggal: "",
        },
        suratKeputusan: {
          pejabat: "",
          nomor: "",
          tanggal: "",
        },
        jumlahKredit: {
          utama: "",
          tambahan: "",
        },
        masaKerjaGolongan: {
          tahun: "",
          bulan: "",
        },
        jenisKP: {
          id: "",
        },
        gajiPokok: "",
      };
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        golongan: {
          id: this.editedItem.golongan.id,
          tmt: this.editedItem.golongan.tmt,
        },
        pertekBkn: {
          nomor: this.editedItem.pertekBkn.nomor,
          tanggal: this.editedItem.pertekBkn.tanggal,
        },
        suratKeputusan: {
          pejabat: this.editedItem.suratKeputusan.pejabat,
          nomor: this.editedItem.suratKeputusan.nomor,
          tanggal: this.editedItem.suratKeputusan.tanggal,
        },
        jumlahKredit: {
          utama: this.editedItem.jumlahKredit.utama,
          tambahan: this.editedItem.jumlahKredit.tambahan,
        },
        masaKerjaGolongan: {
          tahun: this.editedItem.masaKerjaGolongan.tahun,
          bulan: this.editedItem.masaKerjaGolongan.bulan,
        },
        jenisKP: {
          id: this.editedItem.jenisKP.id,
        },
        gajiPokok: this.editedItem.gajiPokok,
      };

      const url = process.env.VUE_APP_ASN + "golongan/nip/" + this.get_nip;
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
            this.closeModal();
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
            this.closeModal();
          }
        });
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwpangkat)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
