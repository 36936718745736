<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on">
        <v-icon>mdi-plus-box</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Tambah Riwayat Penghargaan </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <!-- Penghargaan Tanda Jasa -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Penghargaan Tanda Jasa</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="penghargaan"
                  :items="refPenghargaan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Surat Keputusan (SK) -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="pejabat"
                  hint="Pejabat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="nomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="skDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="tanggal"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tanggal"
                    @input="skDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="tahun"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/rwpenghargaan/viewRwpenghargaan";

export default {
  name: "rwpenghargaanModalAdd",

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refPenghargaan = await getRef.Penghargaan(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,

    // Date Picker
    skDate: false,

    penghargaan: "",
    pejabat: "",
    nomor: "",
    tanggal: "",
    tahun: "",

    refPenghargaan: [],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.btnLoading = false;

      let data = {
        penghargaan: {
          id: this.penghargaan,
        },
        suratKeputusan: {
          pejabat: this.pejabat,
          nomor: this.nomor,
          tanggal: this.tanggal,
        },
        tahun: this.tahun,
      };

      const url = process.env.VUE_APP_ASN + "penghargaan/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
