<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>
          Tambah Riwayat Sasaran Kinerja Pegawai
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline error--text">
            PERINGATAN!
          </v-card-title>
          <v-card-text class="subtitle-1"
            >Jenis Jabatan Rangkap Tidak Bisa Dipilih.</v-card-text
          >
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="tutupDialog">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <div>
            <span class="subtitle-1"><b>Riwayat SKP</b></span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis Jabatan / Eselon</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="jenisJabatan"
                  :items="refJenisjabatan"
                  item-text="nama"
                  item-value="id"
                  hint="Jenis Jabatan"
                  persistent-hint
                  dense
                  outlined
                  @change="onChange"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="tahun"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="this.tahun == 2021">
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis Peraturan Kinerja</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="jenisPP"
                  :items="refjpp"
                  item-text="nama"
                  item-value="id"
                  dense
                  outlined
                  @change="onJPP"
                  :rules="fRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <div>
            <span class="subtitle-1">
              <b><i>Sasaran Kinerja Pegawai</i></b>
            </span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nilai SKP</span>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model.number="nilaiSKP"
                  dense
                  flat
                  outlined
                  type="number"
                  maxlength="5"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="1">
                <v-text-field
                  v-model.number="enampuluhpersen"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model.number="hasilnilaiSKP"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <div>
            <span class="subtitle-1">
              <b><i>Perilaku Kerja</i></b>
            </span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" class="ml-n1">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Orientasi Pelayanan</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiOP"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiOP)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Komitmen</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiKO"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiKO)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Kerjasama</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiKE"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiKE)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Nilai Perilaku Kerja</span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.number="nilaiPerilakuKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="empatpuluhpersen"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.number="hasilnilaiPerilakuKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>
                        <b><i>Nilai Prestasi Kerja</i></b>
                      </span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="nilaiPrestasiKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiPrestasiKerja)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>

              <v-col cols="12" md="6">
                <v-col cols="12" v-if="integritasView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Integritas</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiINT"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiINT)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5" v-if="disiplinView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Disiplin</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiDIS"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiDIS)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5" v-if="inisiatifkerjaView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Inisiatif Kerja</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiIK"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiIK)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col v-if="kepemimpinanView" cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Kepemimpinan</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiKEP"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiKEP)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6">
                <div class="mt-n3">
                  <span class="subtitle-1">
                    <b>Pejabat Penilai</b>
                  </span>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>NIP / NRP</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="nipPP"
                          dense
                          flat
                          outlined
                          persistent-hint
                          :hint="
                            messageNIPCari != '' ? '* ' + messageNIPCari : ''
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-btn
                          small
                          depressed
                          v-if="btnLoadingCariPP"
                          color="primary"
                          dark
                          class="mt-1"
                          @click="pejabatPenilai()"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          loading
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Nama</span>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="namaPP"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Jabatan</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="jabatanPP"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Unor</span>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          v-model="unorPP"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Golongan</span>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="golonganPP"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-menu
                          v-model="ppDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="tmtPP"
                              hint="TMT Golongan"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              dense
                              flat
                              outlined
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tmtPP"
                            @input="ppDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>

              <v-col cols="12" md="6">
                <div class="mt-n3">
                  <span class="subtitle-1">
                    <b>Atasan Pejabat Penilai</b>
                  </span>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>NIP / NRP</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="nipAtasan"
                          dense
                          flat
                          outlined
                          persistent-hint
                          :hint="
                            messageAtasanNIPCari != ''
                              ? '* ' + messageAtasanNIPCari
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-btn
                          small
                          v-if="btnLoadingCariAtasanPP"
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          @click="atasanPenilai()"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          loading
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Nama</span>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="namaAtasan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Jabatan</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="jabatanAtasan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Unor</span>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          v-model="unorAtasan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Golongan</span>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="golonganAtasan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-menu
                          v-model="atasanDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="tmtAtasan"
                              hint="TMT Golongan"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              dense
                              flat
                              outlined
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="tmtAtasan"
                            @input="atasanDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <span class="error--text mr-5" v-if="fKosong"
              >*Harap Periksa Kembali Inputan Anda!</span
            >
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
// import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/pns/rwskp/viewRwskp";

export default {
  name: "rwskpModalAdd",

  data: () => ({
    token: Cookie.get("token"),
    messageNIPCari: "",
    messageAtasanNIPCari: "",

    atasanDate: false,
    ppDate: false,

    ModalAdd: false,
    dialog: false,
    btnLoading: true,
    btnLoadingCariPP: true,
    btnLoadingCariAtasanPP: true,

    inisiatifkerjaView: false,
    kepemimpinanView: false,
    integritasView: true,
    disiplinView: true,

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
    fKosong: false,

    // perhitungan
    pembagi: 5,

    // Referensi
    refJenisjabatan: [],
    refjpp: [
      {
        id: "PP46",
        nama: "Peraturan Pemerintah Nomor 46",
      },
      {
        id: "PP30",
        nama: "Peraturan Pemerintah Nomor 30",
      },
    ],

    // v-model
    nilaiSKP: 0,
    nilaiOP: 0,
    nilaiKO: 0,
    nilaiKE: 0,
    nilaiINT: 0,
    nilaiDIS: 0,
    nilaiIK: 0,
    nilaiKEP: 0,

    tahun: 0,
    jenisPP: "",
    jenisJabatan: "",

    idPP: "",
    nipPP: "",
    namaPP: "",
    unorPP: "",
    golonganPP: "",
    jabatanPP: "",
    tmtPP: "",
    statusPP: "",

    idAtasan: "",
    nipAtasan: "",
    namaAtasan: "",
    jabatanAtasan: "",
    unorAtasan: "",
    golonganAtasan: "",
    tmtAtasan: "",
    statusAtasan: "",
  }),

  computed: {
    enampuluhpersen: function () {
      if (this.tahun == 2021) {
        if (this.jenisPP == "PP30") {
          return "70%";
        } else {
          return "60%";
        }
      } else if (this.tahun > 2021) {
        return "70%";
      } else {
        return "60%";
      }
    },

    empatpuluhpersen: function () {
      if (this.tahun == 2021) {
        if (this.jenisPP == "PP30") {
          return "30%";
        } else {
          return "40%";
        }
      } else if (this.tahun > 2021) {
        return "30%";
      } else {
        return "40%";
      }
    },

    hasilnilaiSKP: function () {
      if (this.tahun > 2021) {
        return this.nilaiSKP * 0.7;
      } else if (this.tahun < 2021) {
        return this.nilaiSKP * 0.6;
      } else if (this.tahun == 2021) {
        if (this.jenisPP == "PP30") {
          return this.nilaiSKP * 0.7;
        } else {
          return this.nilaiSKP * 0.6;
        }
      } else {
        return this.nilaiSKP * 0.7;
      }
    },

    hasilnilaiPerilakuKerja: function () {
      if (this.tahun > 2021) {
        return this.nilaiPerilakuKerja * 0.3;
      } else if (this.tahun < 2021) {
        return this.nilaiPerilakuKerja * 0.4;
      } else if (this.tahun == 2021) {
        if (this.jenisPP == "PP30") {
          return this.nilaiPerilakuKerja * 0.3;
        } else {
          return this.nilaiPerilakuKerja * 0.4;
        }
      } else {
        return this.nilaiPerilakuKerja * 0.3;
      }
    },

    nilaiPerilakuKerja: function () {
      let np;

      if (this.jenisJabatan == 1) {
        if (this.tahun == 2021) {
          if (this.jenisPP == "PP30") {
            np = parseFloat(
              (this.nilaiOP +
                this.nilaiKO +
                this.nilaiKE +
                this.nilaiIK +
                this.nilaiKEP) /
                this.pembagi
            ).toFixed(2);
          } else {
            np = parseFloat(
              (this.nilaiOP +
                this.nilaiKO +
                this.nilaiKE +
                this.nilaiINT +
                this.nilaiDIS +
                this.nilaiKEP) /
                this.pembagi
            ).toFixed(2);
          }
        } else if (this.tahun > 2021) {
          np = parseFloat(
            (this.nilaiOP +
              this.nilaiKO +
              this.nilaiKE +
              this.nilaiIK +
              this.nilaiKEP) /
              this.pembagi
          ).toFixed(2);
        } else {
          np = parseFloat(
            (this.nilaiOP +
              this.nilaiKO +
              this.nilaiKE +
              this.nilaiINT +
              this.nilaiDIS +
              this.nilaiKEP) /
              this.pembagi
          ).toFixed(2);
        }
      } else {
        if (this.jenisPP == "PP30") {
          np = parseFloat(
            (this.nilaiOP + this.nilaiKO + this.nilaiKE + this.nilaiIK) /
              this.pembagi
          ).toFixed(2);
        } else {
          np = parseFloat(
            (this.nilaiOP +
              this.nilaiKO +
              this.nilaiKE +
              this.nilaiINT +
              this.nilaiDIS) /
              this.pembagi
          ).toFixed(2);
        }
      }

      return np;

      // if (this.pembagi == 6) {
      //   return parseFloat(
      //     (this.nilaiOP +
      //       this.nilaiKO +
      //       this.nilaiKE +
      //       this.nilaiINT +
      //       this.nilaiDIS +
      //       this.nilaiKEP) /
      //       this.pembagi
      //   ).toFixed(2)
      // } else if (this.pembagi == 4) {
      //   return parseFloat(
      //     (this.nilaiOP + this.nilaiKO + this.nilaiKE + this.nilaiIK) /
      //       this.pembagi
      //   ).toFixed(2)
      // } else {
      //   return parseFloat(
      //     (this.nilaiOP +
      //       this.nilaiKO +
      //       this.nilaiKE +
      //       this.nilaiINT +
      //       this.nilaiDIS) /
      //       this.pembagi
      //   ).toFixed(2)
      // }
    },

    nilaiPrestasiKerja: function () {
      let hasil = this.hasilnilaiSKP + this.hasilnilaiPerilakuKerja;
      return parseFloat(hasil).toFixed(2);
    },

    jumlah: function () {
      return (
        this.nilaiOP +
        this.nilaiKO +
        this.nilaiKE +
        this.nilaiINT +
        this.nilaiDIS +
        this.nilaiIK +
        this.nilaiKEP
      );
    },
  },

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refJenisjabatan = await getRef.Jenisjabatan(this.token);
      }
    },

    tahun(value) {
      if (this.jenisJabatan == 1) {
        if (value > 2021) {
          this.inisiatifkerjaView = true;
          this.integritasView = false;
          this.disiplinView = false;
          this.nilaiINT = 0;
          this.nilaiDIS = 0;
          this.pembagi = 5;
        } else {
          this.inisiatifkerjaView = false;
          this.integritasView = true;
          this.disiplinView = true;
          this.nilaiIK = 0;
          this.pembagi = 6;
        }
      } else {
        if (value > 2021) {
          this.inisiatifkerjaView = true;
          this.integritasView = false;
          this.disiplinView = false;
          this.nilaiINT = 0;
          this.nilaiDIS = 0;
          this.pembagi = 4;
        } else {
          this.inisiatifkerjaView = false;
          this.integritasView = true;
          this.disiplinView = true;
          this.nilaiIK = 0;
          this.pembagi = 5;
        }
      }
    },

    jenisPP(value) {
      if (this.jenisJabatan == 1) {
        if (this.tahun == 2021) {
          if (value == "PP30") {
            this.inisiatifkerjaView = true;
            this.integritasView = false;
            this.disiplinView = false;
            this.nilaiINT = 0;
            this.nilaiDIS = 0;
            this.pembagi = 5;
          } else {
            this.inisiatifkerjaView = false;
            this.integritasView = true;
            this.disiplinView = true;
            this.nilaiIK = 0;
            this.pembagi = 6;
          }
        }
      } else {
        if (value == "PP30") {
          this.inisiatifkerjaView = true;
          this.integritasView = false;
          this.disiplinView = false;
          this.nilaiINT = 0;
          this.nilaiDIS = 0;
          this.pembagi = 4;
        } else {
          this.inisiatifkerjaView = false;
          this.integritasView = true;
          this.disiplinView = true;
          this.nilaiIK = 0;
          this.pembagi = 5;
        }
      }
    },

    // jenisJabatan(value) {
    //   if (value == 1) {
    //     if (this.tahun > 2021) {
    //       this.inisiatifkerjaView = true
    //       this.integritasView = false
    //       this.disiplinView = false
    //       this.nilaiINT = 0
    //       this.nilaiDIS = 0
    //       this.pembagi = 5
    //     } else {
    //       this.inisiatifkerjaView = false
    //       this.integritasView = true
    //       this.disiplinView = true
    //       this.nilaiIK = 0
    //       this.pembagi = 6
    //     }
    //   } else {
    //     if (value > 2021) {
    //       this.inisiatifkerjaView = true
    //       this.integritasView = false
    //       this.disiplinView = false
    //       this.nilaiINT = 0
    //       this.nilaiDIS = 0
    //       this.pembagi = 4
    //     } else {
    //       this.inisiatifkerjaView = false
    //       this.integritasView = true
    //       this.disiplinView = true
    //       this.nilaiIK = 0
    //       this.pembagi = 5
    //     }
    //   }
    // },
  },

  async mounted() {
    this.token = await Cookie.get("token");
    this.get_nip = this.$route.params.id;
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      this.nilaiSKP = 0;
      this.nilaiOP = 0;
      this.nilaiKO = 0;
      this.nilaiKE = 0;
      this.nilaiINT = 0;
      this.nilaiDIS = 0;
      this.nilaiKEP = 0;
      this.nilaiIK = 0;
      this.tahun = 0;

      this.jenisJabatan = "";
      this.idPP = "";
      this.nipPP = "";
      this.namaPP = "";
      this.unorPP = "";
      this.golonganPP = "";
      this.jabatanPP = "";
      this.tmtPP = "";
      this.statusPP = "";
      this.idAtasan = "";
      this.nipAtasan = "";
      this.namaAtasan = "";
      this.jabatanAtasan = "";
      this.unorAtasan = "";
      this.golonganAtasan = "";
      this.tmtAtasan = "";
      this.statusAtasan = "";

      this.fKosong = false;
    },

    async getData() {
      const url = process.env.VUE_APP_ASN + "datautama/nip/" + this.get_nip;

      try {
        const response = await this.http.get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        });
        return response.data.mapData.DataUtama;
      } catch (error) {
        return error.response.data;
      }
    },

    pejabatPenilai() {
      this.btnLoadingCariPP = false;
      this.messageNIPCari = "";

      const url = process.env.VUE_APP_ASN + "skp/cari/" + this.nipPP;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.idPP = response.data.mapData.DataUtama.sapk.id;
            this.namaPP = response.data.mapData.DataUtama.nama;
            this.jabatanPP = response.data.mapData.DataUtama.jabatanNama;
            this.unorPP = response.data.mapData.DataUtama.unorIndukNama;
            this.golonganPP = response.data.mapData.DataUtama.golRuangAkhir;
            this.tmtPP = response.data.mapData.DataUtama.tmtGolAkhir;
            this.btnLoadingCariPP = true;
          } else {
            this.messageNIPCari = response.data.message;
            this.btnLoadingCariPP = true;
          }
        })
        .catch((error) => {
          this.messageNIPCari = "";
          if (error.response.status == 401) {
            // Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
            this.btnLoadingCariAtasanPP = true;
          } else {
            console.log("Error", error.message);
            this.btnLoadingCariAtasanPP = true;
          }
        });
    },

    atasanPenilai() {
      this.btnLoadingCariAtasanPP = false;
      this.messageAtasanNIPCari = "";

      const url = process.env.VUE_APP_ASN + "skp/cari/" + this.nipAtasan;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.idAtasan = response.data.mapData.DataUtama.sapk.id;
            this.namaAtasan = response.data.mapData.DataUtama.nama;
            this.jabatanAtasan = response.data.mapData.DataUtama.jabatanNama;
            this.unorAtasan = response.data.mapData.DataUtama.unorIndukNama;
            this.golonganAtasan = response.data.mapData.DataUtama.golRuangAkhir;
            this.tmtAtasan = response.data.mapData.DataUtama.tmtGolAkhir;
            this.btnLoadingCariAtasanPP = true;
          } else {
            this.messageAtasanNIPCari = response.data.message;
            this.btnLoadingCariAtasanPP = true;
          }
        })
        .catch((error) => {
          this.messageAtasanNIPCari = "";
          if (error.response.status == 401) {
            // Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
            this.btnLoadingCariAtasanPP = true;
          } else {
            console.log("Error", error.message);
            this.btnLoadingCariAtasanPP = true;
          }
        });
    },

    async add() {
      this.btnLoading = false;
      this.validate();
      if (this.validate()) {
        this.fKosong = false;

        let data = {
          jenisJabatan: this.jenisJabatan,
          tahun: this.tahun,
          jenisPeraturanKinerja: this.jenisPP,
          nilai: {
            nilaiSkp: this.nilaiSKP,
            orientasiPelayanan: this.nilaiOP,
            integritas: this.nilaiINT,
            komitmen: this.nilaiKO,
            disiplin: this.nilaiDIS,
            kerjasama: this.nilaiKE,
            nilaiPerilakuKerja: this.nilaiPerilakuKerja,
            nilaiPrestasiKerja: this.nilaiPrestasiKerja,
            kepemimpinan: this.nilaiKEP,
            inisiatifKerja: this.nilaiIK,
            jumlah: this.jumlah,
            nilairatarata: this.nilaiPerilakuKerja,
          },

          pejabatPenilai: {
            pejabatPenilai: "",
            penilaiNipNrp: this.nipPP,
            penilaiNama: this.namaPP,
            penilaiUnorNama: this.unorPP,
            penilaiJabatan: this.jabatanPP,
            penilaiGolongan: this.golonganPP,
            penilaiTmtGolongan: this.tmtPP,
            statusPenilai: "PNS",
          },

          atasanPejabatPenilai: {
            atasanPejabatPenilai: "",
            atasanPenilaiNipNrp: this.nipAtasan,
            atasanPenilaiNama: this.namaAtasan,
            atasanPenilaiUnorNama: this.unorAtasan,
            atasanPenilaiJabatan: this.jabatanAtasan,
            atasanPenilaiGolongan: this.golonganAtasan,
            atasanPenilaiTmtGolongan: this.tmtAtasan,
            statusAtasanPenilai: "PNS",
          },
        };

        const url = process.env.VUE_APP_ASN + "skp/nip/" + this.get_nip;

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.fKosong = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              // Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.btnLoading = true;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.btnLoading = true;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.fKosong = true;
        this.btnLoading = true;
      }
    },

    onChange(value) {
      if (value == 3) {
        this.dialog = true;
      }
      if (value == 1) {
        this.kepemimpinanView = true;
        this.pembagi = 6;
      } else {
        this.kepemimpinanView = false;
        this.nilaiKEP = 0;
        this.pembagi = 5;
      }
    },

    onJPP(value) {
      if (value == "PP30") {
        this.inisiatifkerjaView = true;
        this.integritasView = false;
        this.disiplinView = false;
        this.nilaiINT = 0;
        this.nilaiDIS = 0;
        this.pembagi = 4;
      } else {
        this.inisiatifkerjaView = false;
        this.integritasView = true;
        this.disiplinView = true;
        this.nilaiIK = 0;
        this.pembagi = 5;
      }
    },

    tutupDialog() {
      this.jenisJabatan = "";
      this.dialog = false;
    },

    convertPersen(value) {
      return parseFloat(value).toFixed(2) + "%";
    },

    predikat(value) {
      let angka = parseInt(value);
      let predikat;
      if (angka <= 50) {
        predikat = "Sangat Kurang";
      } else if (angka >= 51 && angka <= 61) {
        predikat = "Kurang";
      } else if (angka >= 61 && angka <= 75) {
        predikat = "Cukup";
      } else if (angka >= 76 && angka <= 90) {
        predikat = "Baik";
      } else if (angka >= 91 && angka <= 100) {
        predikat = "Sangat Baik";
      } else {
        predikat = "Tidak Ada Dalam Daftar Predikat";
      }

      return predikat;
    },

    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
