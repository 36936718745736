import axios from "axios";
import Auth from '@/helper/auth.js'

const base_url = process.env.VUE_APP_ASN

export default {
    Agama: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/agama'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.agama
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Kawin: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/kawin'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.kawin
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Jenispegawai: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jenispegawai'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jenisPegawai
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    kedudukanHukum: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/kedudukanhukum'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.kedudukanHukum
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Jenisjabatan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jenisjabatan'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jabatanJenis
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Eselon: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/eselon'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.eselon
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Jabatanfungsional: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jafung'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jabatanFungsional
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Jabatanfungsionalumum: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jfu'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jabatanFungsionalUmum
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Golongan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/golongan'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.golongan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Organisasi: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/organisasi'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.organisasi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Instansi: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/instansi'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.instansi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    SatuanKerja: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/satuankerja'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.satuanKerja
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Unor: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'unor/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    subUnor: async (token, id) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'unor/' + id

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Lokasi: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/lokasi'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.lokasi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    subLokasi: async (token, id) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/lokasi/' + id

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.lokasi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    jenisKP: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jeniskp/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jabatanKenaikanPangkat
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Pendidikan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/pendidikan/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.pendidikan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    subPendidikan: async (token, id) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/pendidikan/' + id

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.pendidikan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    LatihanStruktural: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/latihanstr/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.latihanStruktural
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    LatihanStrukturalKualifi: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/latihanstrkualifi/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.latihanStrukturalKualifikasi
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    JenisKursus: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/jeniskursus/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.jenisKursus
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }

    },

    Penghargaan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/penghargaan/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.penghargaan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Statusdok: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'ref/stsdocu/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.statusDocu
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    newUnor: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = base_url + 'unor/active'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    // SISPEK
    Proses: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_KENPA + 'ref/proses'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.proses
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    Status: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_KENPA + 'ref/status'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.status
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    // KGB
    kStatus: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_KGB + 'ref/status'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.status
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    // CUTI
    cStatus: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_CUTI + 'ref/status'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.status
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    jCuti: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_CUTI + 'ref/jenis'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.proses
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    lCuti: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_CUTI + 'ref/lama'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.proses
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    aCuti: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_CUTI + 'ref/acc'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.proses
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },


    // TUBEL
    tStatus: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_TUBEL + 'ref/status'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.status
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },


    tPendidikan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_TUBEL + 'ref/pendidikan/'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.pendidikan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },

    tsubPendidikan: async (token, id) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_TUBEL + 'ref/pendidikan/' + id

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.referensi.pendidikan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },


    // HUKDIS
    hPeraturan: async (token) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_DISIPLIN + 'ref/peraturan/all'

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.peraturan
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },
    hPasal: async (token, id) => {
        let headers = {
            Authorization: 'Bearer ' + token
        }

        let url = process.env.VUE_APP_DISIPLIN + 'ref/peraturan/' + id

        try {
            const response = await axios.get(url, {
                headers: headers
            })
            return response.data.mapData.pasal
        } catch (error) {
            if (error.response.status == 401) {
                Auth.logout(token)
            } else {
                return []
            }
        }
    },
}