<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>
          Tambah Data Pasangan
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Pasangan -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Pasangan</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="nama"
                  hint="Nama Pasangan"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="NIK"
                  hint="NIK Pasangan"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="jenisKelamin"
                  :items="jenkel"
                  hint="Jenis Kelamin"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglLahir"
                    @input="tglLahirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglMeninggal"
                    @input="tglMeninggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Pernikahan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Pernikahan</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="aktaMenikah"
                  hint="Akta Menikah"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="tgglMenikahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgglMenikah"
                      hint="Tanggal Menikah"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgglMenikah"
                    @input="tgglMenikahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Perceraian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Perceraian</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="aktaCerai"
                  hint="Akta Cerai"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="tgglCeraiPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tgglCerai"
                      hint="Tanggal Cerai"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tgglCerai"
                    @input="tgglCeraiPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/pns/rwpasangan/viewRwpasangan";

export default {
  name: "rwpasanganModalAdd",

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,

    jenkel: ["Pria", "Wanita"],

    // Date Picker
    tglLahirPicker: false,
    tglMeninggalPicker: false,
    tgglMenikahPicker: false,
    tgglCeraiPicker: false,

    // variable
    NIK: "",
    nama: "",
    tempatLahir: "",
    tglLahir: "",
    aktaMeninggal: "",
    tglMeninggal: null,
    jenisKelamin: "",
    aktaMenikah: "",
    tgglMenikah: null,
    aktaCerai: "",
    tgglCerai: null,
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    async add() {
      this.btnLoading = false;

      let data = {
        NIK: this.NIK,
        nama: this.nama,
        tempatLahir: this.tempatLahir,
        tglLahir: this.tglLahir,
        aktaMeninggal: this.aktaMeninggal,
        tglMeninggal: this.tglMeninggal,
        jenisKelamin: this.jenisKelamin,
        dataPernikahan: {
          aktaMenikah: this.aktaMenikah,
          tgglMenikah: this.tgglMenikah,
          aktaCerai: this.aktaCerai,
          tgglCerai: this.tgglCerai,
        },
      };

      const url = process.env.VUE_APP_ASN + "pasangan/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },
    closeModal() {
      this.ModalAdd = false;
    },
  },
};
</script>
