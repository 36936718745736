<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Riwayat Kursus </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Diklat -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kursus</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="editedItem.jenisKursus.id"
                  :items="refKursus"
                  item-value="id"
                  item-text="nama"
                  hint="Jenis Kursus"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.namaKursus"
                  hint="Nama Kursus"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="editedItem.jenisKursus.sertifikat"
                  :items="refSertifikat"
                  item-value="id"
                  item-text="nama"
                  hint="Sertifikat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal / Jam Pelaksanaan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal / Jam Pelaksanaan</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.tempat"
                  hint="Tempat Pelaksanaan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAwalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.pelaksanaan.awal"
                      hint="Tanggal Awal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.pelaksanaan.awal"
                    @input="tglAwalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglAkhirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.pelaksanaan.akhir"
                      hint="Tanggal Akhir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.pelaksanaan.akhir"
                    @input="tglAkhirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.pelaksanaan.jp"
                  hint="Jam"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Penyelenggara -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Penyelenggara</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="editedItem.institusiPenyelenggara"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Sertifikat -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Sertifikat</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.noSertipikat"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tglKursusPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="editedItem.tanggalKursus"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tanggalKursus"
                    @input="tglKursusPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.tahunKursus"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/rwkursus/viewRwkursus";
import modal_editState from "@/store/pns/rwkursus/modal_edit";

export default {
  name: "rwkursusModalEdit",

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refKursus = await getRef.JenisKursus(this.token);
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwkursus;
      },

      set(value) {
        console.log(value);
        // modal_editState.commit("editModal", value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    rwkursus: {},

    // Date Picker
    tglAwalPicker: false,
    tglAkhirPicker: false,
    tglKursusPicker: false,

    // Select
    refKursus: [],
    refSertifikat: [
      {
        id: "KURSUS",
        nama: "Kursus",
      },
      {
        id: "SERTIFIKAT",
        nama: "Sertifikasi",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.rwkursus = {
        id: "",
        sapk: {
          id: "",
        },
        jenisKursus: {
          id: null,
          nama: null,
          sertifikat: "",
        },
        namaKursus: "",
        noSertipikat: "",
        tanggalKursus: "",
        tahunKursus: "",
        institusiPenyelenggara: "",
        tempat: "",
        pelaksanaan: {
          awal: "",
          akhir: "",
          jp: "",
        },
        userId: null,
        NCSISTIME: null,
      };
    },
    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        jenisKursus: {
          id: this.editedItem.jenisKursus.id,
          sertifikat: this.editedItem.jenisKursus.sertifikat,
        },
        namaKursus: this.editedItem.namaKursus,
        noSertipikat: this.editedItem.noSertipikat,
        tanggalKursus: this.editedItem.tanggalKursus,
        tahunKursus: this.editedItem.tahunKursus,
        institusiPenyelenggara: this.editedItem.institusiPenyelenggara,
        tempat: this.editedItem.tempat,
        pelaksanaan: {
          awal: this.editedItem.pelaksanaan.awal,
          akhir: this.editedItem.pelaksanaan.akhir,
          jp: this.editedItem.pelaksanaan.jp,
        },
      };

      const url = process.env.VUE_APP_ASN + "kursus/nip/" + this.get_nip;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwkursus)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
