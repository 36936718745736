<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Riwayat Diklat </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Bahasa -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Bahasa</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.bahasa"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kemampuan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kemampuan</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="editedItem.kemampuan"
                  :items="refkemampuan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Jenis -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-model="editedItem.jenis"
                  :items="refjenis"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/pns/rwbahasa/viewRwbahasa";
import modal_editState from "@/store/pns/rwbahasa/modal_edit";

export default {
  name: "rwbahasaModalEdit",

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwbahasa;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,

    refkemampuan: [
      { id: "AKTIF", nama: "Aktif" },
      { id: "PASIF", nama: "Pasif" },
    ],

    refjenis: [
      { Id: "Asing", nama: "Asing" },
      { id: "Daerah", nama: "Daerah" },
    ],

    rwbahasa: {},
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.rwbahasa = {
        id: "",
        bahasa: "",
        kemampuan: "",
        jenis: "",
        userId: null,
        NCSISTIME: null,
      };
    },
    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        bahasa: this.editedItem.bahasa,
        kemampuan: this.editedItem.kemampuan.toUpperCase(),
        jenis: this.editedItem.jenis,
        userId: null,
        NCSISTIME: null,
      };

      const url = process.env.VUE_APP_ASN + "bahasa/nip/" + this.get_nip;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwbahasa)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
