<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            v-bind="attrs"
            @click="getData()"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Tambah Data Anak </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Anak -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Anak</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="nama"
                  hint="Nama Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="NIK"
                  hint="NIK Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="jenisKelamin"
                  :items="jenkel"
                  hint="Jenis Kelamin"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglLahir"
                    @input="tglLahirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglMeninggal"
                    @input="tglMeninggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nama Orang Tua -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Orang Tua</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-model="ortu"
                  :items="refOrtu"
                  item-value="id"
                  item-text="nama"
                  hint="Nama Orang Tua"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="jenisAnak"
                  :items="refAnak"
                  item-value="id"
                  item-text="nama"
                  hint="Status Anak"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/pns/rwanak/viewRwanak";

export default {
  name: "rwanakModalAdd",

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,
    get_nip: "",

    refAnak: [
      {
        id: "kandung",
        nama: "Kandung",
      },
      {
        id: "angkat",
        nama: "Angkat",
      },
      {
        id: "tiri",
        nama: "Tiri",
      },
    ],
    jenkel: ["Pria", "Wanita"],
    refOrtu: [],

    // Date Picker
    tglLahirPicker: false,
    tglMeninggalPicker: false,

    // variable
    NIK: null,
    nama: "",
    tempatLahir: "",
    tglLahir: "",
    aktaMeninggal: null,
    tglMeninggal: null,
    jenisKelamin: "",
    ortu: "",
    jenisAnak: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    async getData() {
      const url = process.env.VUE_APP_ASN + "anak/nip/" + this.get_nip + "/ortu";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.refOrtu = response.data.mapData.DataOrtu;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async add() {
      this.btnLoading = false;

      let data = {
        NIK: this.NIK,
        nama: this.nama,
        tempatLahir: this.tempatLahir,
        tglLahir: this.tglLahir,
        aktaMeninggal: this.aktaMeninggal,
        tglMeninggal: this.tglMeninggal,
        jenisKelamin: this.jenisKelamin,
        ortu: {
          id: this.ortu,
          jenisAnak: this.jenisAnak,
        },
      };

      const url = process.env.VUE_APP_ASN + "anak/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },
    closeModal() {
      this.ModalAdd = false;
    },
  },
};
</script>
