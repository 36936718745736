<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Riwayat Kenaikan Gaji Berkala </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- TMT Gaji Berkala -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>TMT Gaji Berkala</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tmtDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.tmt"
                      hint="Tanggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tmt"
                    @input="tmtDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Surat Keputusan (SK) -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.suratKeputusan.pejabat"
                  hint="Pejabat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.suratKeputusan.nomor"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="skDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.suratKeputusan.tanggal"
                      hint="Tanggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.suratKeputusan.tanggal"
                    @input="skDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Pangkat -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Pangkat</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="editedItem.golongan.id"
                  :items="refGolongan"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Gaji Pokok / Masa Kerja -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Gaji Pokok / Masa Kerja</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.gajiPokok"
                  persistent-hint
                  hint="Gaji Pokok"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.masaKerja.tahun"
                  persistent-hint
                  hint="Masa Kerja Tahun"
                  dense
                  outlined
                  maxlength="2"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.masaKerja.bulan"
                  persistent-hint
                  hint="Masa Kerja Bulan"
                  dense
                  outlined
                  maxlength="2"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/rwgaji/viewRwgaji";
import modal_editState from "@/store/pns/rwgaji/modal_edit";

export default {
  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refGolongan = await getRef.Golongan(this.token);
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwgaji;
      },
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    get_nip: "",
    btnLoading: true,

    // Date Picker
    date: new Date().toISOString().substr(0, 10),
    skDate: false,
    tmtDate: false,
    suratkeputusanDate: false,

    tmt: "",
    pejabat: "",
    nomor: "",
    tanggal: "",
    golongan: "",
    gajiPokok: "",
    tahun: "",
    bulan: "",

    // Select
    refGolongan: [],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        tmt: this.editedItem.tmt,
        suratKeputusan: {
          pejabat: this.editedItem.suratKeputusan.pejabat,
          nomor: this.editedItem.suratKeputusan.nomor,
          tanggal: this.editedItem.suratKeputusan.tanggal,
        },
        masaKerja: {
          tahun: this.editedItem.masaKerja.tahun,
          bulan: this.editedItem.masaKerja.bulan,
        },
        gajiPokok: this.editedItem.gajiPokok,
        golongan: {
          id: this.editedItem.golongan.id,
        },
      };

      const url = process.env.VUE_APP_ASN + "kgb/nip/" + this.get_nip;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.reset();
          this.ModalEdit = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
            this.reset();
            this.ModalEdit = false;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
            this.reset();
            this.ModalEdit = false;
          }
        });
    },

    async closeModal() {
      await this.reset();
      // await modal_editState.commit('editModal', Object.assign({}, this.rwgaji))
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
