<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            @click="openM()"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data Pribadi</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Data Pribadi </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeM()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="ml-5 mr-5 mt-6" v-if="data">
        <v-form ref="form">
          <div>
            <v-row v-if="data.unor">
              <v-col cols="12" md="8" class="mb-n6">
                <span class="subtitle-2">Unit Organisasi</span>
                <v-autocomplete
                  v-model="data.unor.id"
                  dense
                  outlined
                  readonly
                  filled
                  :items="refUnor"
                  item-value="idUnor"
                  item-text="nmUnor"
                  class="mt-2"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <!-- KIRI -->
              <v-col cols="12" md="4">
                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">NIP Baru</span>
                    <v-text-field
                      v-model="data.nipBaru"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">NIP Lama</span>
                    <v-text-field
                      v-model="data.nipLama"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Nama</span>
                    <v-text-field
                      v-model="data.nama"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Gelar Depan</span>
                    <v-text-field
                      v-model="data.gelarDepan"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Gelar Belakang</span>
                    <v-text-field
                      v-model="data.gelarBelakang"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.tempatLahir">
                    <span class="subtitle-2">Tempat Lahir</span>
                    <v-text-field
                      v-model="data.tempatLahir.nama"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Tanggal Lahir</span>
                    <v-text-field
                      v-model="data.tglLahir"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" v-if="data.jenisKelamin">
                    <span class="subtitle-2">Jenis Kelamin</span>
                    <v-select
                      v-model="data.jenisKelamin.id"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                      :items="refKelamin"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.agama">
                    <span class="subtitle-2">Agama</span>
                    <v-select
                      v-model="data.agama.id"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      :items="refAgama"
                      item-value="id"
                      item-text="nama"
                      class="mt-2"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Email</span>
                    <v-text-field
                      v-model="data.email"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- KANAN -->
              <v-col cols="12" md="8">
                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12">
                    <span class="subtitle-2">Alamat</span>
                    <v-textarea
                      v-model="data.alamat"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      rows="4"
                      row-height="30"
                      class="mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Nomor Telepon</span>
                    <v-text-field
                      v-model="data.noTelp"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Nomor HP</span>
                    <v-text-field
                      v-model="data.noHp"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.jenisKawin">
                    <span class="subtitle-2">Status Kawin</span>
                    <v-select
                      v-model="data.jenisKawin.id"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                      :items="refKawin"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="12" v-if="data.jenisPegawai">
                    <span class="subtitle-2">Jenis Pegawai</span>
                    <v-select
                      v-model="data.jenisPegawai.id"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                      :items="refJenispegawaian"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Status Pegawai</span>
                    <v-text-field
                      v-model="data.statusASN"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" v-if="data.kedudukanHukum">
                    <span class="subtitle-2">Kedudukan Hukum ASN</span>
                    <v-select
                      v-model="data.kedudukanHukum.id"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                      :items="refkedudukanHukum"
                      item-value="id"
                      item-text="nama"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row dense class="mb-n5" v-if="data.data">
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Status Data</span>
                    <v-text-field
                      v-model="data.data.status"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="subtitle-2">Keterangan</span>
                    <v-text-field
                      v-model="data.data.keterangan"
                      dense
                      flat
                      outlined
                      readonly
                      filled
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <hr class="my-3" />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()">
              SIMPAN
            </v-btn>
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

export default {
  data: () => ({
    token: Cookie.get("token"),
    ModalEdit: false,
    btnLoading: true,

    refKelamin: [
      { id: "M", nama: "Pria" },
      { id: "F", nama: "Wanita" },
    ],
    satusItem: ["active", "inactive"],
    refAgama: [],
    refKawin: [],
    refJenispegawaian: [],
    refkedudukanHukum: [],
    refLokasi: [],
    refUnor: [],
    refKabupaten: {},
    refKecamatan: {},

    data: {},
  }),

  methods: {
    closeM() {
      this.ModalEdit = false;
    },

    async openM() {
      this.ModalEdit = true;
      this.refUnor = await getRef.newUnor(this.token);
      this.refAgama = await getRef.Agama(this.token);
      this.refKawin = await getRef.Kawin(this.token);
      this.refJenispegawaian = await getRef.Jenispegawai(this.token);
      this.refkedudukanHukum = await getRef.kedudukanHukum(this.token);
      this.refLokasi = await getRef.Lokasi(this.token);

      this.getData();
    },

    getData() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN + "datautama/nip/" + this.$route.params.id;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.data = res.data.mapData.DataUtama;
            this.loading = false;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
