<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="6">
        <h2>Data Keterangan Badan & Tubuh</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <databadanModalEdit />
      </v-col>
    </v-row>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Data</th>
              <th class="text-left">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Golongan Darah</td>
              <td>{{ golonganDarah }}</td>
            </tr>
            <tr>
              <td>Tinggi Badan</td>
              <td>{{ tinggiBadan }} Cm</td>
            </tr>
            <tr>
              <td>Berat Badan</td>
              <td>{{ beratBadan }} Kg</td>
            </tr>
            <tr>
              <td>Rambut</td>
              <td>{{ janisRambut }}</td>
            </tr>
            <tr>
              <td>Bentuk Muka</td>
              <td>{{ bentukWajah }}</td>
            </tr>
            <tr>
              <td>Warna Kulit</td>
              <td>{{ warnaKulit }}</td>
            </tr>
            <tr>
              <td>Ciri-Ciri Khas</td>
              <td>{{ ciriBadan }}</td>
            </tr>
            <tr>
              <td>Cacat Tubuh</td>
              <td>{{ cacatTubuh }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import databadanModalEdit from "@/components/pns/profil/databadan/ModalEdit";

import refreshView from "@/store/pns/databadan/viewDatabadan";

export default {
  name: "databadan",

  components: {
    databadanModalEdit,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    golonganDarah: "",
    tinggiBadan: "",
    beratBadan: "",
    janisRambut: "",
    bentukWajah: "",
    warnaKulit: "",
    ciriBadan: "",
    cacatTubuh: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      const url = process.env.VUE_APP_ASN + "fisik/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          refreshView.commit("refreshData", false);
          this.golonganDarah = response.data.mapData.DataFisik.golonganDarah;
          this.tinggiBadan = response.data.mapData.DataFisik.tinggiBadan;
          this.beratBadan = response.data.mapData.DataFisik.beratBadan;
          this.janisRambut = response.data.mapData.DataFisik.janisRambut;
          this.bentukWajah = response.data.mapData.DataFisik.bentukWajah;
          this.warnaKulit = response.data.mapData.DataFisik.warnaKulit;
          this.ciriBadan = response.data.mapData.DataFisik.ciriBadan;
          this.cacatTubuh = response.data.mapData.DataFisik.cacatTubuh;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>
