<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Tambah Riwayat Pendidikan / Sekolah </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="ModalAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <!-- Tingkat Pendidikan -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tingkat Pendidikan</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="PendidikanTerakhir"
                  hint="Pendidikan Terakhir"
                  persistent-hint
                  :items="refPendidikan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                  return-object
                  @change="changePT"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="NamaPendidikan"
                  hint="Nama Pendidikan"
                  persistent-hint
                  :items="refSubPendidikan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nama / Tempat Sekolah / PT -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama / Tempat Sekolah</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="namaSekolah"
                  hint="Nama"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="lokasiSekolah"
                  hint="Tempat"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Ijazah -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Ijazah</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="nomorIjasah"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="lulusDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="tglLulus"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tglLulus"
                    @input="lulusDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="tahunLulus"
                  hint="Tahun Lulus"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kepsek / Ketua / Rektor -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kepsek / Ketua / Rektor</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="namaKepalaSekolah"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Gelar -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Gelar</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="depan"
                  hint="Depan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="belakang"
                  hint="Belakang"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/pns/rwpendidikan/viewRwpendidikan";

export default {
  name: "rwpendidikanModalAdd",

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refPendidikan = await getRef.Pendidikan(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ModalAdd: false,
    btnLoading: true,

    // Date Picker
    tglLulus: new Date().toISOString().substr(0, 10),
    lulusDate: false,

    PendidikanTerakhir: "",
    depan: "",
    belakang: "",
    NamaPendidikan: "",
    namaSekolah: "",
    lokasiSekolah: "",
    tahunLulus: "",
    nomorIjasah: "",
    namaKepalaSekolah: "",

    refPendidikan: [],
    refSubPendidikan: [],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.btnLoading = false;

      let data = {
        pendidikan: {
          id: this.NamaPendidikan,
          gelar: {
            depan: this.depan,
            belakang: this.belakang,
          },
        },
        namaSekolah: this.namaSekolah,
        lokasiSekolah: this.lokasiSekolah,
        tahunLulus: this.tahunLulus,
        nomorIjasah: this.nomorIjasah,
        namaKepalaSekolah: this.namaKepalaSekolah,
        tglLulus: this.tglLulus,
      };

      const url = process.env.VUE_APP_ASN + "pendidikan/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    async changePT(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subPendidikan(token, value.id);
      this.refSubPendidikan = response;
    },

    closeModal() {
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
